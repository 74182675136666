import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`本アプリは日本語をいろいろな文章に翻訳・変換するジェネレーターの機能を持つアプリです。
いつも使っている日本語を面白い形に変換できます。`}</p>
    <h4>{`開発者様へ`}</h4>
    <p>{`このアプリは Web 上にある外部の文章ジェネレーターを利用することができます。
もし文章ジェネレーターを本アプリ上で動かしたい場合はご連絡ください。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      