import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="FiNAX FANTASY 風" link="https://racing-lagoon.info/nomu/translate.php" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  世界的な人気を誇る、古の時代からあるとされるファンタジー神に創造されし亜空の箱庭のような文章を生成できます、いつの日か世界を救うと信じて――。(ノムリッシュ)
    </ProjectCard>
    <ProjectCard title="ざわ・・・風" link="https://racing-lagoon.info/fuku/translate.php" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  あの圧倒的・・・！圧倒的賭博漫画のような文章を生成できたのだっ・・・・・！っ・・・・！
    </ProjectCard>
    <ProjectCard title="意識高い系 風" link="https://bizwd.net/" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
ビジネス書をインプットしまくって日々グロースハックしているアイツのようなテキストをオートでWriteできます。
    </ProjectCard>
    <ProjectCard title="某戦闘民族 風" link="https://goku-lang.netlify.app/" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  オッス！あの戦闘民族風の文章が作成できっぞ！
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      