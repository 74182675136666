import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`お問い合わせ`}</h2>
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeu2S8IaHTiKHNo9WXMgxwasb9LepGhf090kn3eCHnXz1A90g/viewform?embedded=true" width="640" height="800" frameBorder="0" marginHeight="0" marginWidth="0">
  読み込んでいます…
    </iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      